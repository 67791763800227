var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-update-sale-payment",
        "no-close-on-backdrop": "",
        "hide-header-close": "",
        "hide-footer": "",
        size: "md",
        centered: "",
      },
      on: { hidden: _vm.onHideModal },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              _c("p", { staticClass: "h4" }, [
                _vm._v(" " + _vm._s(_vm.$t("Alterar pagamento")) + " "),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "FormulateForm",
        {
          ref: "updateSalePaymentForm",
          attrs: { name: "updateSalePaymentForm" },
        },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex flex-column", attrs: { md: "12" } },
                    [
                      _c("e-payment-method", {
                        attrs: {
                          id: "modal_sale_payment-payment_method",
                          required: true,
                          flag: "pdv",
                        },
                        model: {
                          value: _vm.form.paymentMethodId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "paymentMethodId", $$v)
                          },
                          expression: "form.paymentMethodId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex justify-content-end",
                      staticStyle: { gap: "10px" },
                    },
                    [
                      _c("e-button", {
                        attrs: {
                          text: _vm.$t("Voltar"),
                          "text-shortcuts": ["ESC"],
                          variant: "outline-primary",
                        },
                        on: { click: _vm.onHideModal },
                      }),
                      _c("e-button", {
                        attrs: {
                          variant: "primary",
                          type: "submit",
                          busy: _vm.busy,
                          text: _vm.$t("Confirmar"),
                        },
                        on: { click: _vm.onConfirm },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }