<template>
  <b-modal
    id="modal-update-sale-payment"
    no-close-on-backdrop
    hide-header-close
    hide-footer
    size="md"
    centered
    @hidden="onHideModal"
  >
    <template #modal-title>
      <p class="h4">
        {{ $t('Alterar pagamento') }}
      </p>
    </template>

    <FormulateForm
      ref="updateSalePaymentForm"
      name="updateSalePaymentForm"
    >
      <b-container>
        <b-row>
          <b-col
            md="12"
            class="d-flex flex-column"
          >
            <e-payment-method
              id="modal_sale_payment-payment_method"
              v-model="form.paymentMethodId"
              :required="true"
              :flag="'pdv'"
            />
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col
            class="d-flex justify-content-end"
            style="gap: 10px"
          >
            <e-button
              :text="$t('Voltar')"
              :text-shortcuts="['ESC']"
              variant="outline-primary"
              @click="onHideModal"
            />
            <e-button
              variant="primary"
              type="submit"
              :busy="busy"
              :text="$t('Confirmar')"
              @click="onConfirm"
            />
          </b-col>
        </b-row>
      </b-container>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { BRow, BCol, BModal, BContainer } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'
import EPaymentMethod from '@/views/components/inputs/EPaymentMethod.vue'
import { paymentTypes } from '@/mixins'

const getInitialForm = () => ({
  salePaymentId: null,
  paymentMethodId: null,
})

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BContainer,
    EButton,
    EPaymentMethod,
  },

  mixins: [paymentTypes],

  data() {
    return {
      busy: false,
      form: getInitialForm(),
    }
  },

  methods: {
    async show(salePayment) {
      const { PIX, CREDIT_CARD, DEBIT_CARD } = this.paymentTypeEnum
      const { TEF } = this.paymentMethodInterfaceEnum
      const allowedMethods = [PIX, CREDIT_CARD, DEBIT_CARD]
      if (!allowedMethods.includes(salePayment.paymentMethod.method)) {
        this.showWarning({
          message: this.$t(
            'Somente Cartão de Crédito, Cartão de Débito ou PIX Manual podem ser alterados'
          ),
        })
        return
      }

      if (salePayment.paymentMethodInterface === TEF) {
        this.showWarning({
          message: this.$t('Somente POS ou PIX (Manual) podem ser alterados'),
        })
        return
      }

      this.form.salePaymentId = salePayment.id
      this.$bvModal.show('modal-update-sale-payment')
    },

    onHideModal() {
      this.resetModal()
      this.$bvModal.hide('modal-update-sale-payment')
    },

    resetModal() {
      this.form = getInitialForm()
    },

    async onConfirm() {
      if (this.$refs.updateSalePaymentForm.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      try {
        this.busy = true
        await this.$http.patch('/api/sales/payments/payment-method', {
          id: this.form.salePaymentId,
          paymentMethodId: this.form.paymentMethodId,
        })

        this.$emit('after-confirm')

        this.showSuccess({})
        this.onHideModal()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.busy = false
      }
    },
  },
}
</script>

<style></style>
