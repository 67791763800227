import { render, staticRenderFns } from "./SaleInfoSidebar.vue?vue&type=template&id=c176a444&scoped=true&"
import script from "./SaleInfoSidebar.vue?vue&type=script&lang=js&"
export * from "./SaleInfoSidebar.vue?vue&type=script&lang=js&"
import style0 from "./SaleInfoSidebar.vue?vue&type=style&index=0&id=c176a444&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c176a444",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/echope/echope-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c176a444')) {
      api.createRecord('c176a444', component.options)
    } else {
      api.reload('c176a444', component.options)
    }
    module.hot.accept("./SaleInfoSidebar.vue?vue&type=template&id=c176a444&scoped=true&", function () {
      api.rerender('c176a444', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/pdv/pay-box-manage-sale/SaleInfoSidebar.vue"
export default component.exports