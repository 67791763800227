var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("e-sidebar", {
        attrs: {
          title: _vm.isPendingLayout
            ? _vm.$t("Venda Pendente")
            : _vm.$t("Gerenciar venda"),
          show: _vm.showSidebar,
          fetching: _vm.fetching,
          saving: _vm.saving,
          width: "900px",
        },
        on: { hidden: _vm.hide },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "b-card-actions",
                  {
                    attrs: {
                      "no-actions": "",
                      title: _vm.$t("Dados da venda"),
                    },
                  },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "3" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "sidebar_info_sale-sale_id",
                                label: _vm.$t("Venda"),
                                type: "label",
                              },
                              model: {
                                value: _vm.saleInfo.id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.saleInfo, "id", $$v)
                                },
                                expression: "saleInfo.id",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "6" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "sidebar_info_sale-customer",
                                value: _vm.getCustomerName,
                                label: _vm.$t("Cliente"),
                                type: "label",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "3" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "sidebar_info_sale-status",
                                value: _vm.saleInfo.status
                                  ? _vm.saleInfo.status
                                  : "-",
                                label: _vm.$t("Status da venda"),
                                type: "label",
                                options: _vm.saleStatusOptions(),
                                "is-badge": "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "mb-1" },
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("p", { staticClass: "h6" }, [
                              _vm._v(" " + _vm._s(_vm.$t("Entrega?")) + " "),
                            ]),
                            _c("e-status-badge", {
                              attrs: {
                                status: _vm.saleInfo.isDelivery,
                                type: "yesNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("p", { staticClass: "h6" }, [
                              _vm._v(" " + _vm._s(_vm.$t("Consignado?")) + " "),
                            ]),
                            _c("e-status-badge", {
                              attrs: { status: _vm.isConsigned, type: "yesNo" },
                            }),
                          ],
                          1
                        ),
                        _vm.getBarTabConsumptionId
                          ? _c(
                              "b-col",
                              { attrs: { md: "3" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "sidebar_info_sale-consumption_id",
                                    value: _vm.saleInfo.barTabConsumption.id,
                                    label: _vm.$t("Consumo no Bar"),
                                    type: "label",
                                    icons: _vm.consumptionIdLabelIcons,
                                  },
                                  on: { link: _vm.showBarConsumption },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.saleInfo.invoice &&
                        _vm.saleInfo.invoice.model === "NFE"
                          ? _c(
                              "b-col",
                              { attrs: { md: "2" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "order_maintain-invoice-id",
                                    value:
                                      _vm.saleInfo.invoice.invoiceNumber +
                                      " | " +
                                      _vm.invoiceModelLabelShort[
                                        _vm.saleInfo.invoice.model
                                      ],
                                    type: "label",
                                    label: _vm.$t("Nota fiscal"),
                                    "router-name": "invoice-maintain",
                                    "router-params": {
                                      id: _vm.saleInfo.invoice.id,
                                    },
                                    "router-title":
                                      _vm.$t("Visualizar nota fiscal") +
                                      " - " +
                                      _vm.statusInvoiceLabel[
                                        _vm.saleInfo.invoice.status
                                      ],
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "mb-1" },
                      [
                        _c(
                          "b-col",
                          [
                            _c("p", { staticClass: "h5" }, [
                              _vm._v(" " + _vm._s(_vm.$t("Produtos")) + ": "),
                            ]),
                            _c("e-pay-box-sale-item-table", {
                              staticClass: "bordered",
                              attrs: {
                                "table-items": _vm.getItems,
                                "item-read-only": "",
                                "prices-to-right": "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "b-col",
                          [
                            _c("p", { staticClass: "h5" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("Métodos de pagamento")) +
                                  ": "
                              ),
                            ]),
                            _c("b-table", {
                              staticClass: "bordered",
                              attrs: {
                                striped: "",
                                "show-empty": "",
                                "empty-text": _vm.$t(
                                  "Nenhum pagamento registrado."
                                ),
                                fields: _vm.paymentFields,
                                items: _vm.getPayments,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "cell(action)",
                                  fn: function (row) {
                                    return [
                                      _c("e-grid-actions", {
                                        attrs: {
                                          "show-update": _vm.$can(
                                            "UpdatePaymentMethod",
                                            "Sale"
                                          ),
                                          "show-delete":
                                            _vm.isTefAndSaleCanceled(row.item),
                                          "delete-title":
                                            _vm.isTefAndSaleCanceled(row.item)
                                              ? _vm.$t(
                                                  "PAY_BOX_SALE.TEF.CANCEL_TEF"
                                                )
                                              : null,
                                          buttons: _vm.buttonsPayments(row),
                                        },
                                        on: {
                                          update: function ($event) {
                                            return _vm.showUpdatePaymentModal(
                                              row.item
                                            )
                                          },
                                          delete: function ($event) {
                                            return _vm.cancelSalePaymentTef(
                                              row.item
                                            )
                                          },
                                          "print-receipt": function ($event) {
                                            return _vm.onPrintReceipt(row.item)
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(paymentMethod)",
                                  fn: function (row) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(row.item.paymentMethod.name) +
                                          " "
                                      ),
                                      row.item.cardBrand || row.item.nsu
                                        ? [
                                            _vm._v(" ( "),
                                            row.item.paymentMethodInterface
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm
                                                          .paymentMethodInterfaceLabel[
                                                          row.item
                                                            .paymentMethodInterface
                                                        ] || "-"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              : _vm._e(),
                                            row.item.cardBrand
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.item.cardBrand ||
                                                          "-"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              : _vm._e(),
                                            row.item.nsu
                                              ? [
                                                  _c("b", [_vm._v("NSU: ")]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.item.nsu || "-"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm._v(" ) "),
                                          ]
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "mb-2" },
                      [
                        _c("b-col", [
                          _c("div", { staticClass: "line-total" }, [
                            _c("p", { staticClass: "h4" }, [
                              _vm._v(" Subtotal: "),
                            ]),
                            _c("p", { staticClass: "h4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(_vm.getSaleSubtotal)
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "line-total" }, [
                            _c("p", { staticClass: "h4" }, [
                              _vm._v(" Desconto: "),
                            ]),
                            _c("p", { staticClass: "h4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("currency")(_vm.getDiscount)) +
                                  " "
                              ),
                            ]),
                          ]),
                          _vm.saleInfo.deliveryForm.deliveryTax > 0
                            ? _c("div", { staticClass: "line-total" }, [
                                _c("p", { staticClass: "h4" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("Taxa de conveniência")) +
                                      ": "
                                  ),
                                ]),
                                _c("p", { staticClass: "h4" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.saleInfo.deliveryForm.deliveryTax
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.saleInfo.comission > 0
                            ? _c("div", { staticClass: "line-total" }, [
                                _c("p", { staticClass: "h4" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Comissão")) + ": "
                                  ),
                                ]),
                                _c("p", { staticClass: "h4" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.saleInfo.comission
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "line-total" }, [
                            _c("p", { staticClass: "h3" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("Total da venda")) + ": "
                              ),
                            ]),
                            _c("p", { staticClass: "h3" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(_vm.saleInfo.netValue)
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.isSaleCanceled
                  ? _c(
                      "b-card-actions",
                      {
                        attrs: {
                          "no-actions": "",
                          title: _vm.$t("Dados do cancelamento"),
                        },
                      },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "3" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "sidebar_info_sale-canceled_date",
                                    label: _vm.$t("Data/Hora"),
                                    type: "label",
                                    filter: "datetime",
                                  },
                                  model: {
                                    value: _vm.saleInfo.cancelationData.date,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.saleInfo.cancelationData,
                                        "date",
                                        $$v
                                      )
                                    },
                                    expression: "saleInfo.cancelationData.date",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "5" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "sidebar_info_sale-canceled_user",
                                    label: _vm.$t("Usuário que cancelou"),
                                    type: "label",
                                  },
                                  model: {
                                    value: _vm.getCancelationUser,
                                    callback: function ($$v) {
                                      _vm.getCancelationUser = $$v
                                    },
                                    expression: "getCancelationUser",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "sidebar_info_sale-cancel_observation",
                                    label: _vm.$t("Observação"),
                                    type: "label",
                                  },
                                  model: {
                                    value: _vm.saleInfo.cancelationData.reason,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.saleInfo.cancelationData,
                                        "reason",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "saleInfo.cancelationData.reason",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showDeliveryData
                  ? _c(
                      "b-card-actions",
                      {
                        attrs: {
                          "no-actions": "",
                          title: _vm.$t("Dados da entrega"),
                        },
                      },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "3" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "sidebar_info_sale-delivery_date",
                                    label: _vm.$t("Data/hora"),
                                    type: "label",
                                    filter: "datetime",
                                  },
                                  model: {
                                    value:
                                      _vm.saleInfo.deliveryForm.deliveryDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.saleInfo.deliveryForm,
                                        "deliveryDate",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "saleInfo.deliveryForm.deliveryDate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "6" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "sidebar_info_sale-delivery_observation",
                                    label: _vm.$t("Observação"),
                                    type: "label",
                                  },
                                  model: {
                                    value:
                                      _vm.saleInfo.deliveryForm.observation,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.saleInfo.deliveryForm,
                                        "observation",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "saleInfo.deliveryForm.observation",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "3" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "sidebar_info_sale-delivery_status",
                                    value: _vm.getDeliveryStatus
                                      ? _vm.getDeliveryStatus
                                      : "-",
                                    label: _vm.$t("Status"),
                                    type: "label",
                                    options: _vm.deliveryStatusOptions(),
                                    "is-badge": "",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "sidebar_info_sale-delivery_address",
                                    value: _vm.getDeliveryAddressFormated,
                                    label: _vm.$t("Endereço"),
                                    type: "label",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "sidebar-footer",
            fn: function () {
              return [
                _c("div", { staticClass: "w-100" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex w-100 justify-content-end align-items-center",
                      staticStyle: { gap: "10px" },
                    },
                    [
                      _vm.hasDeliveryStep
                        ? [
                            _vm.isDeliveryPending
                              ? _c("e-button", {
                                  attrs: {
                                    id: "sidebar_info_sale-btn_prepare_delivery",
                                    variant: "primary",
                                    icon: "box-seam",
                                    text: _vm.$t("Preparar entrega"),
                                    busy: _vm.saving,
                                    disabled: _vm.fetching,
                                  },
                                  on: { click: _vm.onPrepareDelivery },
                                })
                              : _vm._e(),
                            _vm.isDeliveryPreparing
                              ? _c("e-button", {
                                  attrs: {
                                    id: "sidebar_info_sale-btn_start_delivery",
                                    variant: "primary",
                                    icon: "truck",
                                    text: _vm.$t("Realizar entrega"),
                                    busy: _vm.saving,
                                    disabled: _vm.fetching,
                                  },
                                  on: { click: _vm.onStartDelivery },
                                })
                              : _vm._e(),
                            _vm.isDeliveryInProgress
                              ? _c("e-button", {
                                  attrs: {
                                    id: "sidebar_info_sale-btn_complete_delivery",
                                    variant: "primary",
                                    icon: "check-square",
                                    text: _vm.$t("Concluir entrega"),
                                    busy: _vm.saving,
                                    disabled: _vm.fetching,
                                  },
                                  on: { click: _vm.onFinishDelivery },
                                })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm.showCancelButton
                        ? _c("e-button", {
                            attrs: {
                              id: "sidebar_info_sale-btn_cancel",
                              variant: "danger",
                              icon: "x-circle",
                              text: _vm.$t("Cancelar venda"),
                              busy: _vm.saving,
                              disabled: _vm.fetching,
                            },
                            on: { click: _vm.onCancel },
                          })
                        : _vm._e(),
                      _c("e-button", {
                        attrs: {
                          id: "sidebar_info_sale-btn_print_coupon",
                          variant: "primary",
                          icon: "printer-fill",
                          text: _vm.$t("Imprimir"),
                          busy: _vm.saving,
                          disabled: _vm.fetching,
                        },
                        on: { click: _vm.onPrintInvoiceCoupon },
                      }),
                      _vm.isPendingLayout
                        ? _c("e-button", {
                            attrs: {
                              id: "sidebar_info_sale-btn_finish",
                              variant: "primary",
                              icon: "check-circle",
                              text: _vm.btnFinishLabel,
                              busy: _vm.saving,
                              disabled: _vm.fetching,
                            },
                            on: { click: _vm.onSendSaleToPayBox },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "e-modal-custom-form",
        {
          ref: "modalCancelForm",
          attrs: { title: "Dados para cancelar a venda" },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "d-flex flex-column", attrs: { md: "12" } },
                [
                  _c("FormulateInput", {
                    staticClass: "w-100 required",
                    attrs: {
                      id: "sidebar_info_sale-modal_cancel-reason",
                      name: "reason",
                      type: "textarea",
                      validation: "required|min:15,length",
                      rows: "4",
                      label: _vm.$t("Motivo do cancelamento"),
                    },
                    model: {
                      value: _vm.cancelForm.cancelationReason,
                      callback: function ($$v) {
                        _vm.$set(_vm.cancelForm, "cancelationReason", $$v)
                      },
                      expression: "cancelForm.cancelationReason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("modal-update-sale-payment", {
        ref: "modalSalePayment",
        on: { "after-confirm": _vm.onUpdateSaleInfo },
      }),
      _c("bar-tab-consumption-info-sidebar", {
        ref: "barTabConsumptionSidebar",
        attrs: { "is-history-view": "", "read-only": "" },
        on: { "show-sale": _vm.onHideConsumptionSidebar },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }